.app {
  position: relative;
}

.yearWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-inline: 32px;

  @media (max-width: 600px) {
    padding-inline: 8px 0;
  }
}

.stickyPlayer {
  background: var(--colorWhite);
  bottom: 48px;
  box-shadow: 0 12px 24px 18px var(--colorPrimary);
  opacity: 0;
  position: fixed;
  right: 32px;
  transition: opacity 300ms ease-in-out;
  z-index: 1;

  @media (max-width: 600px) {
    background: var(--colorPrimary);
    position: sticky;
    top: 0;
  }
}

.show {
  opacity: 1;
}
