@import 'assets/fonts/fonts';

html,
body,
main,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
select,
textarea,
button {
  border: none;
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: none;
  vertical-align: baseline;
}

html {
  --colorPrimary: #615b58;
  --colorSecondary: #ffff20;
  --colorTertiary: #0549fe;
  --colorWhite: #fff;
  --colorBlack: #000;
  --colorNeutral100: #f6f7f7;
  --colorNeutral200: #a1a8a4;
  --colorNeutral300: #636b66;
  --colorNeutral400: #404642;
  --colorNeutral500: #303231;

  --colorApple: #fb5c74;
  --colorSpotify: #1db954;
  --colorYouTube: #ff0000;
  --colorInstagram: #ff0069;

  --stickyHeight: 100px;

  background-color: var(--colorPrimary);
  color: var(--colorNeutral100);
  overflow-x: hidden;

  @media (max-width: 600px) {
    --stickyHeight: 160px;
  }
}

body {
  // JW - here to change font!
  font-family: 'Mondia', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 18px;
  }
}
