.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  margin: 0 auto;
  text-align: left;
  width: inherit;

  img {
    object-fit: contain;
    max-height: 40svh;
    max-width: 100%;

    @media (max-width: 600px) {
      max-height: 50svh;
    }
  }

  p {
    text-align: left;
  }
}

// .imageLeft {
//   align-self: flex-start;
// }

// .imageRight {
//   align-self: flex-end;
// }
